import Vue from "vue";
import App from "./App.vue";
import createRouter from "./router";
import store from "./store";
import { sessionStore, persistingStore } from './store/index'
import axios from "axios";

import flatpickr from "flatpickr";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { InlineSvgPlugin } from "vue-inline-svg";
import { PinnaclePermissions, PinnacleEnvironments } from 'pinnacle-lib';
import VueObserveVisibility from 'vue-observe-visibility';
import { VuePlugin } from 'vuera';

Vue.use(VuePlugin);

Vue.use(VueObserveVisibility);

Vue.config.productionTip = false;
Vue.use(flatpickr);

// Setup for axios
Vue.prototype.$http = axios.create({
  baseURL: process.env.VUE_APP_PINNACLE_API,
});
Vue.prototype.$sessionStore = sessionStore;
Vue.prototype.$persistingStore = persistingStore;

Vue.use(PinnaclePermissions());
Vue.use(PinnacleEnvironments());

// Inline SVG
Vue.use(InlineSvgPlugin);

// Install BootstrapVue from pinnacle-lib
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);


import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css";
import "flatpickr/dist/flatpickr.min.css";

import "pinnacle-lib/assets/scss/_user.scss";
import "pinnacle-lib/assets/scss/theme.scss";
import "pinnacle-lib/styles/global.scss";
import "@/assets/scss/map-overrides.scss"

import "pinnacle-lib/assets/js/chart.js";
import "pinnacle-lib/assets/js/clock.js";


Vue.filter("str_limit", (value, size) => {
  if (!value) return "";
  if (!size) size = 23;
  
  value = value.toString();
  
  if (value.length <= size + 3) {
    return value;
  }
  return value.substr(0, size).trim() + "...";
});

Vue.filter("numberToPercentage", (value) => {
  if (!value || isNaN(value)) {
    return value;
  }

  return value.toLocaleString("en", {
    style: "percent",
    minimumFractionDigits: 2,
  });
});

Vue.filter("capitalize", (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

// Brand avatar mixins

const iconContainerStyle = {
  background: '#EAF2FD',
  padding: '8px !important'
};
const logoContainerStyle = {
  textAlign: 'center'
};
const iconStyle = {
  filter: `invert(55%) sepia(40%) saturate(294%) hue-rotate(175deg) brightness(86%) contrast(87%)`
};
const logoStyle = {
};

Vue.mixin({
  methods: {
    getCategoryAvatar(location) {
      // if(!location.avatar_category) return location.avatar_subsector;
      // else if (!location.avatar_subsector.endsWith("svg")) return location.avatar_sector;
      // else return location.avatar_category;

      // if (location.avatar_category && location.avatar_category.endsWith("svg")) return location.avatar_category;
      if (location.avatar_category) return location.avatar_category;
      else if (location.avatar_subsector && location.avatar_subsector.endsWith("svg") && location.avatar_subsector !== "None") return location.avatar_subsector;
      else return location.avatar_sector;

    },
    getImgContainerStyle(location) {
      if (!location || !location.avatar_category) return iconContainerStyle;
      return logoContainerStyle;
    },
    getImgStyle(location) {
      if (!location || (!location.avatar_category && (location.avatar_subsector !== null && location.avatar_subsector.endsWith("svg")))) return iconStyle;
      return logoStyle;
    },
    getImgContainerClass(location) {
      if (!location || !location.avatar_category) return 'fallback-icon';
      return 'avatar-logo';
    },
    goBack(crumb) {
      if (crumb === undefined || crumb === null) this.$router.go(-1);
      if (crumb.path === this.$route.path && JSON.stringify(crumb.query) === JSON.stringify(this.$route.query)) return;
      const newCrumb = Object.assign({}, crumb);

      // TODO: Move this logic to configuration, this is a hack
      if (this.$route.params.ids !== undefined && !this.$route.path.includes('preview')) crumb.params.ids = this.$route.params.ids;

      newCrumb.meta.ignoreCrumb = true;
      this.$sessionStore.commit('popCrumb');
      this.$router.push(newCrumb);
    },
  }
})

export const bus = new Vue();

new Vue({
  router: createRouter(),
  store,
  render: (h) => h(App),
}).$mount("#app");
