<template>
  <div class="header-container">
    <!-- <div class="text-container">
            Comparison Insights
        </div> -->
    <div class="title-container">
      <div class="column">
        <p class="title">Configuration Panel</p>
        <!--p class="title">{{ reportDetails.name }}</p-->
      </div>
    </div>

    <div class="selection">
      <p class="description">Timeframe</p>
      <!-- <p class="edit">Edit</p> -->
    </div>
    <div class="title-container">
      <p class="number">
        07/29/2023 - 07/29/2024
      </p>
    </div>

  </div>
</template>
<script>
import { bus } from "@/main";
import ComparisonInfo from "@/components/partials/ComparisonInfo.vue";

export default {
  props: ["reportDetails","studyLocations"],
  components: {
    ComparisonInfo,
  },
  data() {
    return {
      // number: 1
    };
  },
  methods: {
    copyLink() {
      const clipboardInput = document.createElement("input");
      const url = window.location.href;
      document.body.appendChild(clipboardInput);
      clipboardInput.value = url;
      clipboardInput.select();
      document.execCommand("copy");
      document.body.removeChild(clipboardInput);

      bus.$emit("copyLinkAlert");
    },
  },
};
</script>
<style lang="scss" scoped>
.text-container {
  height: 55px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #e3ebf6;
  background: #ffffff;
  color: #6e84a3;
  font-size: 14px;
  padding-top: 15px;
  padding-left: 24px;
  font-weight: bold;
  line-height: 24px;
}
.header-container {
  background: #ffffff;
  width: 100%;
}
.button-container {
  // margin: 16px 24px;
  padding: 0 1.5rem 0 1.5rem;
  display: flex;
  flex-direction: row;
  text-align: center;
}
.opened-button {
  width: 60%;
  height: 46px;
  border-radius: 4px;
  border-width: 0;
  margin-bottom: 16px;
  background: #6e84a3;
  color: #ffffff;
}
.copy-button {
  width: 40%;
  border-radius: 4px;
  border-width: 0;
  margin-bottom: 16px;
  margin-left: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #edf2f9;
  color: #6e84a3;
}
.selection {
  display: flex;
  background: #f9fafc;
  padding: 18px 24px;
  justify-content: space-between;
  flex-direction: row;
}
.description {
  margin-bottom: 0px;
  color: #6e84a3;
  font-weight: 700;
  font-size: 14px;
}
.edit {
  margin-bottom: 0px;
  font-size: 14px;
  color: #6e84a3;
  font-weight: normal;
}
.max {
  font-weight: normal;
}


.max {
  font-weight: normal;
}
.light {
  background: #ffffff;

  .title {
    color: #12263f;
  }

  .icon {
    background: #eaf2fd;
  }

  .number {
    span {
      color: black;
    }
    color: #6e84a3;
  }
}

.blue {
  background: rgba(44, 123, 229, 1);

  .title {
    color: white;
    font-size: 16px;
  }

  .icon {
    background: rgba(234, 242, 253, 1);
  }

  .number {
    span {
      color: black;
    }
    color: rgba(150, 189, 242, 1);
  }
}

.large {

  .column {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 18px;
    line-height: 23px;
    margin: auto 0;
  }

  .icon {
    width: 48px;
    height: 48px;
    padding-top: 15px;
    padding: 8px;
    border-radius: 4px;
    margin: 16px 24px;
  }

  .number {
    font-size: 14px;
    margin: auto 0;
  }

}

.small {

  gap: 10px;
  
  .column {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }

  .title {
    font-size: 16px;
    margin: auto 0;
  }

  .icon {
    width: 48px;
    height: 48px;
    padding-top: 15px;
    padding: 8px;
    border-radius: 4px;
  }

  .number {
    font-size: 12px;
    margin: auto 0;
  }
}

.title-container {
  padding:15px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .column {
    flex-direction: column;
  }
  .title {
    font-weight: bold;
    margin: auto 0;
    font-size: 16px;
  }
  .icon {
    display: flex;
    justify-content: center;
  }
  .number {
    font-weight: bold;
    margin: auto 0;
  }
}
</style>