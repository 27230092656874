import Vue from 'vue';
import VueRouter from "vue-router";
import VueBodyClass from "vue-body-class";
import PublicLayout from "../layouts/PublicLayout.vue";
import AppLayout from "../layouts/AppLayout.vue";
import StudyLayout from "../layouts/StudyLayout.vue";
import VisitReportsLayout from "@/layouts/VisitReportsLayout.vue"
import PreviewLayout from "../layouts/PreviewLayout.vue";
import ShareLayout from "../layouts/ShareLayout.vue";

import Explore from "../views/Explore.vue";
import Documentation from "../views/Documentation.vue";
import CustomerSupport from "../views/CustomerSupport.vue";
import Userinfo from "../views/UserInfo.vue";
import Confirmation from "../views/Confirmation.vue";
import Expire from "../views/Expire.vue";
// import Studies from "../views/Studies.vue";
import StudyMapContainer from "../components/locations/StudyMapContainer.vue";
import LocationCharts from "../components/locations/Charts.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Search from "../components/search/Search.vue";
import SearchDetails from "../components/search/SearchDetails.vue";
import ComparisonDetails from "../components/search/ComparisonDetails.vue";
import PlacesDetailsCard from "../components/partials/PlacesDetailsCard.vue";
import PlaceDetails from "../components/partials/PlaceDetails.vue";
import MapPinDetails from "../components/partials/MapPinDetails.vue";
import PlacesCardHeader from "../components/partials/PlacesCardHeader.vue";
import StudyDetailsCheckbox from "../components/partials/StudyDetailsCheckbox.vue";
import Logout from "../views/Logout.vue";
import Insight from "../components/partials/Insight.vue";

import HomePage from "../components/home/new/HomePage.vue";
import DatasetsTable from "../components/home/new/DatasetsTable.vue";
import DatasetFilters from "../components/home/new/DatasetFilters.vue";
import ReportsTable from "../components/home/new/ReportsTable.vue";
import ReportFilters from "../components/home/new/ReportFilters.vue";
import CreatePage from "../components/home/new/CreatePage.vue";
import FeedLayout from "../components/home/new/FeedLayout.vue";
import CreateDataset from "../components/home/new/create/CreateDataset.vue";
import SelectDatasetInsights from "../components/home/new/create/SelectDatasetInsights.vue";
import SelectDatasetDetails from "../components/home/new/create/SelectDatasetDetails.vue";
import SelectPlaces from "../components/home/new/create/SelectPlaces.vue";
import CreateReport from "../components/home/new/create/CreateReport.vue";
import SelectReportDetails from "../components/home/new/create/SelectReportDetails.vue";
import CreateVisitsReport from "@/components/home/new/create/CreateVisitsReport.vue"
import SelectVisitsReportDetails from "@/components/home/new/create/SelectVisitsReportDetails.vue"
import SelectTimeframe from "../components/home/new/create/SelectTimeframe.vue";
import Constants from "../services/constants.js";

export const reportPerms = ['Super Admin', 'Card Report'];
export const visitsReportPerms = ['Super Admin', 'Pinnacle Visits Report Access'];
export const datasetPerms = ['Super Admin', 'Pinnacle Bulk Export Access'];
export const homePerms = [...reportPerms, ...datasetPerms];
export const explorePerms = ["Pinnacle Enterprise Access", "Pinnacle Mid Tier Access", "Pinnacle Free Access"];

const externalLinks = ["/login", "/register", "/forgot-password", "/accept_invite", "/share/", "/share/:hash", "/embed/", "/embed/:hash"];
const doNotStoreLinks = [...externalLinks, "/settings", "/confirmation", "/share/:hash", "/embed/:hash", "/documentation", "/customer_support", "/logout", "/"];
Vue.use(VueRouter);

function getRoutes() {
  return [
    {
      path: "/login",
      component: PublicLayout,
      children: [
        {
          path: "",
          name: "login",
          component: Login,
        },
      ],
    },
    {
      path: "/share/:hash",
      component: ShareLayout,
    },
    {
      path: "/embed/:hash",
      component: ShareLayout,
    },
    {
      path: "/accept_invite",
      name: "accept invite",
      component: Register,
      props: route => ({ email: route.query.email, token: route.query.invite_token })
    },
    {
      path: "/forgot-password",
      name: "forgot password",
      component: ForgotPassword,
    },
    {
      path: "/",
      component: AppLayout,
      // Redirects handled in AppLayout
      name: 'app-layout',
      children: [
        {
          path: "/documentation",
          component: Documentation,
          name: "documentation",
          meta: {
            crumbName: "Documentation"
          }
        },
        {
          path: "/customer-support",
          component: CustomerSupport,
          name: "customer-support",
          meta: {
            crumbName: "Customer Support"
          }
        },
        {
          path: "/settings",
          component: Userinfo,
          name: "settings",
          meta: {
            crumbName: "Settings"
          }
        },
        {
          path: "/confirmation",
          component: Confirmation,
          name: "confirmation",
          meta: {
            crumbName: "confirmation"
          }
        },
        {
          path: "/expire",
          component: Expire,
          name: "expire",
          meta: {
            crumbName: "Expired"
          }
        },
        {
          path: "/home",
          name: "home",
          component: HomePage,
          redirect: "/home/feeds",
          children: [
            {
              path: "feeds",
              name: "home-feeds",
              component: FeedLayout,
              children: [
                {
                  path: "datasets",
                  name: "home-datasets",
                  meta: {
                    routeGuard: [...datasetPerms],
                  },
                  components: { "table": DatasetsTable, "table-filters": DatasetFilters }
                },
                {
                  path: "reports",
                  name: "home-reports",
                  meta: {
                    routeGuard: [...reportPerms],
                  },
                  components: { "table": ReportsTable, "table-filters": ReportFilters }
                },
                {
                  path: '*',
                  redirect: '/home'
                }
              ]
            },
            {
              path: "create",
              name: "home-create",
              component: CreatePage,
              children: [
                {
                  path: "dataset",
                  name: "create-dataset",
                  component: CreateDataset,
                  meta: {
                    title: "Format",
                    routeGuard: [...datasetPerms],
                    root: true,
                  },
                  children: [
                    {
                      path: "places",
                      name: "create-dataset-places",
                      component: SelectPlaces,
                      meta: {
                        title: "Places",
                        step: 1,
                        routeGuard: [...datasetPerms],
                        allowMultiplePlaces: true,
                        headerTitle: "Choose your Places of Interest",
                        headerDescription:  `Select places from ${ Constants.company_name }'s place library; upload places using a GeoJSON, KML, or ESRI files; or draw a place in your browser.`
                      }
                    },
                    {
                      path: "timeframe",
                      name: "create-dataset-timeframe",
                      component: SelectTimeframe,
                      meta: {
                        step: 2,
                        routeGuard: [...datasetPerms],
                        title: "Timeframe",
                        headerTitle: "Choose the Timeframe for your Datasets",
                        headerDescription: "This defines the date range used for visits to a place. Please note that the date range is set to UTC time for datasets. For most datasets we recommend 12 months. This period accounts for seasonal fluctuation and ensures significant data volume."
                      }
                    },
                    {
                      path: "insights",
                      name: "create-dataset-insights",
                      component: SelectDatasetInsights,
                      meta: {
                        step: 3,
                        routeGuard: [...datasetPerms],
                        title: "Insights"
                      }
                    },
                    {
                      path: "details",
                      name: "create-dataset-details",
                      component: SelectDatasetDetails,
                      meta: {
                        step: 4,
                        title: "Details",
                        routeGuard: [...datasetPerms],
                        submit: true,
                        headerTitle: "Specify Details for your Datasets",
                        headerDescription: "Please provide a name for your delivery. You may customize the values of the other settings or leave them at their defaults."
                      }
                    },
                    {
                      path: '*',
                      redirect: '/home'
                    }
                  ]
                },
                {
                  path: "report",
                  name: "create-report",
                  component: CreateReport,
                  meta: {
                    title: "Format",
                    routeGuard: [...reportPerms],
                    root: true
                  },
                  children: [
                    {
                      path: "place",
                      name: "create-report-places",
                      component: SelectPlaces,
                      meta: {
                        title: "Place",
                        query: {
                          draw: true
                        },
                        step: 1,
                        routeGuard: [...reportPerms],
                        headerTitle: "Choose your Place of Interest",
                        headerDescription: `Select a place from ${ Constants.company_name }'s place library; upload a place using a GeoJSON, KML, or ESRI files; or draw a place in your browser. Visualization reports support only one place at a time.`
                      }
                    },
                    {
                      path: "timeframe",
                      name: "create-report-timeframe",
                      routeGuard: [...reportPerms],
                      component: SelectTimeframe,
                      meta: {
                        title: "Timeframe",
                        step: 2,
                        routeGuard: [...reportPerms],
                        headerTitle: "Choose the Timeframe for your Report",
                        headerDescription: "This defines the date range used for visits to a place. Please note that the date range is set to local time for visualization reports. For most datasets we recommend 12 months. This period accounts for seasonal fluctuation and ensures significant data volume."
                      }
                    },
                    {
                      path: "details",
                      name: "create-report-details",
                      component: SelectReportDetails,
                      meta: {
                        title: "Details",
                        step: 3,
                        submit: true,
                        routeGuard: [...reportPerms],
                        headerTitle: "Specify Details for your Report",
                        headerDescription: "You must provide a unique name for your report. The name you provide will appear at the top of your report and will be visible to those with whom you share it."
                      }
                    },
                    {
                      path: '*',
                      redirect: '/home'
                    }
                  ]
                },
                {
                  path: "visits-report",
                  name: "create-visits-report",
                  component: CreateVisitsReport,
                  meta: {
                    title: "Format",
                    routeGuard: [...visitsReportPerms],
                    root: true
                  },
                  children: [
                    {
                      path: "place",
                      name: "create-visits-report-place",
                      component: SelectPlaces,
                      meta: {
                        title: "Place",
                        query: {
                          //draw: true
                        },
                        step: 1,
                        routeGuard: [...visitsReportPerms],
                        headerTitle: "Choose your Place of Interest",
                        headerDescription: `Select a place from ${ Constants.company_name }'s place library; upload a place using a GeoJSON, KML, or ESRI files; or draw a place in your browser. Visualization reports support only one place at a time.`
                      }
                    },
                    {
                      path: "timeframe",
                      name: "create-visits-report-timeframe",
                      routeGuard: [...visitsReportPerms],
                      component: SelectTimeframe,
                      meta: {
                        title: "Timeframe",
                        step: 2,
                        routeGuard: [...visitsReportPerms],
                        headerTitle: "Choose the Timeframe for your Report",
                        headerDescription: "This defines the date range used for visits to a place. Please note that the date range is set to local time for visualization reports. For most datasets we recommend 12 months. This period accounts for seasonal fluctuation and ensures significant data volume."
                      }
                    },
                    {
                      path: "details",
                      name: "create-visits-report-details",
                      component: SelectVisitsReportDetails,
                      meta: {
                        title: "Details",
                        step: 3,
                        submit: true,
                        routeGuard: [...visitsReportPerms],
                        headerTitle: "Specify Details for your Estimated Daily Report",
                        headerDescription: "You must provide a unique name for your report. The name you provide will appear at the top of your report and will be visible to those with whom you share it."
                      }
                    },
                    {
                      path: '*',
                      redirect: '/home'
                    }
                  ]
                }
              ]
            },
            {
              path: "reports/:id",
              routeGuard: [...reportPerms],
              component: Insight,
              name: "home-reports-view"
            },
            {
              path: "visit-reports/:id",
              routeGuard: [...visitsReportPerms],
              component: VisitReportsLayout,
              name: "visit-reports-view",
            },
            {
              path: '*',
              redirect: '/home'
            }
          ]
        },
        {
          path: "/explore",
          name: 'explore',
          component: Explore,
          redirect: '/explore/search',
          children: [
            {
              path: "search",
              component: Search,
              meta: {
                crumbName: "Search",
                routeGuard: [...explorePerms],
              },
              name: 'search'
            },
            {
              path: "details",
              component: SearchDetails,
              name: 'search-details',
              meta: {
                crumbName: "Place",
                routeGuard: [...explorePerms],
              }
            },
            {
              path: "comparison",
              component: ComparisonDetails,
              name: "comparison-details",
              meta: {
                crumbName: "Comparison",
                routeGuard: [...explorePerms],
              }
            },
            {
              path: '*',
              redirect: '/explore/search'
            }
          ]
        },
        {
          path: "/study/:ids",
          component: StudyLayout,
          children: [
            {
              path: "map",
              name: "study-map",
              components: { default: StudyMapContainer, sidebar: PlacesDetailsCard, header: PlacesCardHeader },
              meta: {
                crumbName: "Comparison",
                routeGuard: [...explorePerms],
              }
            },
            {
              path: "map/details",
              name: "study-map-details",
              components: { default: StudyMapContainer, sidebar: MapPinDetails },
              meta: {
                crumbName: "Place",
                routeGuard: [...explorePerms],
              }
            },
            {
              path: "charts/details/:index",
              name: "study-charts-details",
              components: { default: LocationCharts, sidebar: StudyDetailsCheckbox },
              meta: {
                crumbName: "Place",
                routeGuard: [...explorePerms],
              }
            },
            {
              path: "charts",
              name: "study-charts",
              components: { default: LocationCharts, sidebar: PlacesDetailsCard, header: PlacesCardHeader },
              meta: {
                crumbName: "Comparison",
                routeGuard: [...explorePerms],
              },
              children: [
                {
                  path: "details",
                  components: { sidebar: PlaceDetails },
                  meta: {
                    crumbName: "Place",
                    routeGuard: [...explorePerms],
                  }
                }
              ]
            },
            {
              path: '*',
              redirect: '/explore/search'
            }
          ]
        },
        {
          path: "/preview/:ids",
          component: PreviewLayout,
          children: [
            {
              path: "map",
              name: "preview-map",
              component: StudyMapContainer,
              meta: {
                crumbName: "Maps",
                routeGuard: [...explorePerms],
              },
            },
            {
              path: "charts",
              name: "preview-charts",
              component: LocationCharts,
              meta: {
                crumbName: "Charts",
                routeGuard: [...explorePerms],
              }
            },
            {
              path: "map/details",
              name: "preview-map-details",
              components: { default: StudyMapContainer, sidebar: MapPinDetails },
              meta: {
                crumbName: "Details",
                routeGuard: [...explorePerms],
              }
            },
            {
              path: '*',
              redirect: '/explore/search'
            }
          ]
        },
        {
          path: "/logout",
          name: "logout",
          component: Logout
        }
      ],
    },
    {
      path: "*",
      redirect: "/",
    },
  ]
}

export default function createRouter() {

  const routes = getRoutes();

  const router = new VueRouter({
    mode: 'history',
    routes,
  });


  const vueBodyClass = new VueBodyClass(routes);

  // Checks if a user's session is active
  // If not, it will boot them back to login
  router.beforeEach(async (to, from, next) => {
    vueBodyClass.guard(to, next);
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      if (externalLinks.includes(to.path)) {
        next("/"); // Check AppLayout.vue for the redirect logic
        return;
      } else if (!doNotStoreLinks.includes(to.path)) {
        let hash_path = to.path.substring(0, to.path.lastIndexOf("/")) + '/:hash';
        if (!doNotStoreLinks.includes(hash_path)) {
          localStorage.setItem('last', to.path);
        }
      }
      next();
    } else {
      if (externalLinks.includes(to.path)) {
        next();
      } else if (to.path.indexOf("/share/") === 0) {
        next();
      } else if (to.path.indexOf("/embed/") === 0) {
        next();
      } 
      else if (to.path === "/settings") next("/forgot-password");
      else next("/login");
    }
  });

  return router;
}

window.popStateDetected = false;
window.addEventListener('popstate', () => {
  window.popStateDetected = true;
});