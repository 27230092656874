import chroma from "chroma-js";
import { createMarker } from "@/services/map/markers";
import { provisionAvatar } from "@/utils/avatars";
import getBounds from "@/utils/getBounds";
import mapboxgl from 'mapbox-gl';
import ThreeDimensionControl from "@/services/map/controls/threeDimensionControl";
import StripeSVG from '@/assets/stripe.svg';

export default {
  data() {
    return {
      studyLocationMarkers: {},
      mapLoaded: false,
      hoverIds: [],
      geo_table_mapping: {
        'census': {table_name:'gis.usa_census', id_field:'geoid'},
        'postal': {table_name:'gis.usa_postal1', id_field:'postcode'},
        'municipality': '',
        'admin': {table_name:'gis.usa_admin', id_field:'name'},
        'province': '',
        'country': ''
      },
      popup_data: {
        title: "",
        geolabel: "",
        items: [],
      },
      //map: undefined,
    }
  },
  computed: {
    chartColors() {
      const color = ["#2c7be5", "#ff595e", "#8ac926", "#ffca3a", "#9980FA"];
      const altColor = [
        "rgba(44, 123, 229, 0.1)",
        "rgba(255, 89, 94, 0.2)",
        "rgba(138, 201, 38, 0.2)",
        "rgba(255, 202, 58, 0.2)",
        "rgba(153, 128, 250, 0.2)",
      ];
      const medColor = "rgba(177, 194, 217, 1)";
      const medAltColor = "rgba(177, 194, 217, 0.6)";
      return { color, altColor, medColor, medAltColor };
    }
  },
  methods: {
    /**
     * Calculates the min/max lines.
     * @param {*} data The chart data
     * @param {*} valueField The value field of the data
     * @param {*} indexField The index field of the data
     * @returns {{minLine, maxLine}}
     */
    calcMinMax(data, valueField, indexField) {
      let minLine = {};
      let maxLine = {};
      Object.entries(data).forEach(([_polygonId, polygonData]) => {
        Object.entries(polygonData).forEach(([_rowIdx, rowData]) => {
          const idx = rowData[indexField];
          if (
            !minLine[idx] ||
            rowData[valueField] < minLine[idx][valueField]
          ) {
            minLine[idx] = { ...rowData };
          }
          if (
            !maxLine[idx] ||
            rowData[valueField] > maxLine[idx][valueField]
          ) {
            maxLine[idx] = { ...rowData };
          }

        });
      });
      minLine = _.sortBy(Object.values(minLine), (o) => o[indexField]);
      maxLine = _.sortBy(Object.values(maxLine), (o) => o[indexField]);
      return { minLine, maxLine };
    },
    /**
     * Parses the datasets into CSV format. Only use for Visits by X charts.
     * @param {Array} datasets The array of datasets.
     * @param {Array} labels The row labels.
     * @param {String} commitName The commit name 
     */
    saveVisitsByCSV(datasets, labels, commitName) {
      const tempAdd = this.$route.path.split("/")[1] === "preview" ? this.$sessionStore.state.study.previews : this.$sessionStore.state.study.locations;
      const addArr = [
        this.$sessionStore.state.study.avgAddress.replace(/,/gi, ""),
        "",
        ""
      ];
      const tempRow = [];
      tempAdd.map(e => addArr.push(e.address.replace(/,/gi, "")));
      let rawData = datasets.map((o, idx) => [
        `${o.label.replace(/,/gi, "")} ${addArr[idx]},`.concat(o.data)
      ]);
      rawData = rawData.map(o => [o[0].replace(/,/gi, "\t")]);
      tempRow.push(labels);
      rawData = tempRow.concat(rawData);
      rawData.splice(2, 2);
      let csvContent = "";
      rawData.forEach((rowArray) => {
        let row = rowArray.join("\t");
        csvContent += row + "\r\n";
      });
      this.$sessionStore.commit(commitName, csvContent);
      return csvContent;
    },
    /**
     * Handles the onHover event for dayWeek and dayTime charts.
     * @param {*} res The "onHover" payload.
     */
    handleOnHover(res, dataField = "week") {
      //console.log('handleOnHover TODO');
      if (!this.id) return false;

      let index = this.id.indexOf(res.selected) + 3;
      const { color, medColor, medAltColor, altColor } = this.chartColors;
      if (res.value) {
        //On Mouse Enter
        for (let i = 0; i < this.id.length; i++) {
          this[dataField].line.data.datasets[i + 3].borderColor = altColor[i];
          this[dataField].line.data.datasets[i + 3].backgroundColor = altColor[i];
        }
        this[dataField].line.data.datasets[0].borderColor = medAltColor;
        this[dataField].line.data.datasets[0].backgroundColor = medAltColor;
        if (res.selected === "median") {
          this[dataField].line.data.datasets[0].borderColor = medColor;
          this[dataField].line.data.datasets[0].backgroundColor = medColor;
        } else {
          this[dataField].line.data.datasets[index].borderColor = color[index - 3];
          this[dataField].line.data.datasets[index].backgroundColor =
            color[index - 3];
        }
      } else {
        //On Mouse Leave
        for (let i = 0; i < this.id.length; i++) {
          this[dataField].line.data.datasets[i + 3].borderColor = color[i];
          this[dataField].line.data.datasets[i + 3].backgroundColor = color[i];
        }
        this[dataField].line.data.datasets[0].borderColor = medColor;
        this[dataField].line.data.datasets[0].backgroundColor = medColor;
      }
      this.renderChartRefs(); 
    },
    /**
     * Event handler for combo line + bar charts.
     * Only used for estFoot, dayWeek, dayTime.
     * @param {*} res The "locationsListChanged" event payload.
     * @param {*} charts The object that contains the line and bar chart data.
     * @param {*} rangeBandData The object that contains the minLine and maxLine.
     * @param {*} parseLine A function that takes the line data and transforms the values.
     * @returns {Boolean} Returns true if successful.
     */
    handleLocationsListChanged(res, charts, { minLine, maxLine }, parseLine) {
      if (!charts.line.data) return false;
      if (this.handlingLocationsChange) {
        // Throw it in the async queue
        setTimeout(() => {
          this.handleLocationsListChanged(res, charts, { minLine, maxLine }, parseLine);
        }, 0);
      }
      this.handlingLocationsChange = true;
      if (res.locationId === "median") {
        charts.line.data.datasets[0].hidden = !res.selected;
        charts.bar.data.datasets[0].hidden = !res.selected;
      } else if (res.locationId === "range") {
        this.showRange = res.selected;
        charts.line.data.datasets[1].hidden = !res.selected;
        charts.line.data.datasets[2].hidden = !res.selected;
      } else {
        const index = this.id.indexOf(res.locationId) + 3;
        this.show[index - 3] = res.selected;

        const _updateData = (dataset, line) => {
          dataset.data = parseLine(line);
        }
        _updateData(charts.line.data.datasets[1], maxLine);
        _updateData(charts.line.data.datasets[2], minLine);

        charts.line.data.datasets[index].hidden = !res.selected;
        charts.bar.data.datasets[index - 2].hidden = !res.selected;
      }
      this.handlingLocationsChange = false;
      return true;
    },
    /**
     * Requests the Pinnacle API for chart data.
     * @param {*} endpoint The target endpoint.
     * @param {*} extraParams Any extra params.
     * @returns Returns the axios response.
     */
    async getChartData(endpoint, extraParams) {
      const hasFilterString = this.filterString.length;
      if (typeof this.$route.params.ids === "undefined" && typeof this.$route.params.id === "undefined" ) return;
      try {
        if (this.tokenNeedsRefresh()) await this.refreshToken();
        const headers = this.getHeaders();
        let paramString = '?';
        for (const key in extraParams) {
          if (paramString !== '?') paramString += "&";
          paramString += `${key}=${extraParams[key].toString()}`;
        }
        if (hasFilterString) paramString += `&${this.filterString}`;
        //console.log('getChartData', endpoint + paramString);
        const data = await this.$http.get(endpoint + paramString, headers);
        return data;
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errors.length) {
          this.err = err.response.data.errors[0].message;
        }
        console.log({ err });
      }
    },
    /**
     * Reads from this.chartRefs and attempts to call the render function.
     */
    renderChartRefs() {
      if (this.$refs.chartRefs) {
        this.$refs.chartRefs.forEach(ref => {
          if (ref.render) {
            ref.render();
          }
        });

        return;
      }
      if (!Array.isArray(this.chartRefs)) return;

      this.chartRefs.forEach(ref => {
        
        if (ref && ref.render) {
          ref.render();
        } else {
          if (this.$refs[ref] && this.$refs[ref].render)
            this.$refs[ref].render();
        }
      });
    },

    /************** Map Related methods ***************/
    async initMap() {
      if (!this.locationsLoading) this.locationsReady = true;

      if (typeof mapboxgl != "object" || !this.locationsReady || !this.$refs.mapComponent) {
        setTimeout(this.initMap, 1000);
        return;
      }

      if (this.mapLoaded) return;
      //console.log('initMap');

      mapboxgl.accessToken = process.env.VUE_APP_MAP_ACCESS_TOKEN;

      let mapData = window.localStorage.getItem("lastSelectedMap");
      let mapID = "cku4lf4wi1qn217pq9kh4w3ol";
      if (mapData) {
        mapData = JSON.parse(mapData);
        mapID = mapData.mapID;
      }
      const bounds = getBounds(this.studyLocations);
      const centerInfo = {};
      if (!this.$route.query.center) centerInfo.bounds = bounds;
      else {
        centerInfo.center = JSON.parse(this.$route.query.center);
        centerInfo.zoom = 12;
      }
      const map = new mapboxgl.Map({
        container: this.$refs.mapComponent,
        style: `mapbox://styles/nearco/${mapID}`,
        //style: 'mapbox://styles/mapbox/streets-v12',
        attributionControl: false,
        //preserveDrawingBuffer: true,
        ...centerInfo,
        // transformRequest: (url, resourceType) => {
        //   if (resourceType === "Tile" && url.indexOf("mapbox") === -1) {
        //     if (this.tokenNeedsRefresh()) {
        //       this.refreshToken().then(() => {
        //         const { headers } = this.getHeaders();
        //         return {
        //           url,
        //           headers,
        //         };
        //       });
        //     } else {
        //       const { headers } = this.getHeaders();
        //       return {
        //         url,
        //         headers,
        //       };
        //     }
        //   }
        // },
      });

      const fullscreen = new mapboxgl.FullscreenControl();
      map.addControl(fullscreen);

      const nav = new mapboxgl.NavigationControl({ showCompass: true });
      map.addControl(nav, "top-right");

      const scale = new mapboxgl.ScaleControl({
        maxWidth: 260,
        unit: "imperial",
      });
      map.addControl(scale);

      const attribution = new mapboxgl.AttributionControl({
        compact: false,
      });
      map.addControl(attribution, "bottom-left");

      this.map = map;

      // bus.$off("resizeMap");
      // bus.$on("resizeMap", () => {
      //   setTimeout(() => {
      //     this.map.resize();
      //   }, 1);
      // });
      this.map.on('load', () => {
        this.mapLoaded = true;
        //this.$emit("mapbound");
      });
    },
    StandardDeviation(arr) {

      // Creating the mean with Array.reduce
      let mean = arr.reduce((acc, curr) => {
          return acc + curr
      }, 0) / arr.length;

      // Assigning (value - mean) ^ 2 to
      // every array item
      arr = arr.map((k) => {
          return (k - mean) ** 2
      });

      // Calculating the sum of updated array 
      let sum = arr.reduce((acc, curr) => acc + curr, 0);

      // Calculating the variance
      let variance = sum / arr.length

      // Returning the standard deviation
      return Math.sqrt(variance);
    },
    getStudyLocationColor(id) {
      let colors = this.chartColors.color;
      let index = this.studyLocations.findIndex(l => l.id == id);
      return chroma(colors[index]).rgb();
    },
    generateWeightedPaintExpression(locationBuckets, bucket_attr, property_name, polygonId) {
      let color = this.getStudyLocationColor(polygonId);
      let values_arr = locationBuckets.map(r => r[bucket_attr]);
      let values_mean = values_arr.reduce((acc, curr) => {
        return acc + curr
      }, 0) / values_arr.length;
      let values_std = this.StandardDeviation(values_arr);
      let values_upper_limit = values_mean + values_std*2;
      //console.log("values_upper_limit", values_upper_limit);

      var colorExpression = ["match", ["get", property_name]];
      //console.log("locationBuckets", locationBuckets);
      let geoids = [];
      let filteredBuckets = locationBuckets.filter(b => b.polygon_id == polygonId);
      _.forEach(filteredBuckets, function(locationBucket, id) {
        let GEOID = locationBucket.geoid;
        let value = locationBucket[bucket_attr];
        if (geoids.includes(GEOID)) {
          //console.log('DUPE found', GEOID);
          return;
        }
        geoids.push(GEOID);
        let opacity = value / values_upper_limit;
        if (opacity > 1) opacity = 1;
        if (opacity < 0) opacity = 0;

        if (bucket_attr == "drive_time") {
          let max_time = values_upper_limit;
          value = max_time - value;
          opacity = value / max_time;
          if (opacity > 1) opacity = 1;
          if (opacity < 0) opacity = 0;
          //console.log(opacity);
        }

        let color_average = `rgba(${color.join(",")},${opacity})`;//chroma.average(colors, 'lch', weights).hex();
        colorExpression.push([GEOID], color_average);
      });

      colorExpression.push("rgba(0,0,0,0)");
      //console.log("colorExpression", colorExpression);
      return colorExpression;
    },
    addStudyLocationMarkers() {
      const { color, medColor, medAltColor, altColor } = this.chartColors;
      this.studyLocations.forEach((location, idx) => {
        if (this.studyLocationMarkers[idx]) {
          const marker = this.studyLocationMarkers[idx];
          marker.remove();
          delete this.studyLocationMarkers[idx];
        };
        const { url, type } = provisionAvatar(location);
        this.studyLocationMarkers[idx] = createMarker({
          map: this.map,
          center: location.centroid.coordinates,
          iconURL: url,
          iconType: type,
          classString: 'location-pin-selected',
          iconBGColor: location.baseColor,
          address: location.address,
          label: location.name,
          loc: location,
          index: idx,
          iconSize: "18px",
          // comparisonLocations: this.comparisonLocations,
          zIndex: 2,
          //click_handler: this.openLocationDetails,
        });


        // create polygon shape
        let source_id = `store-outline-${idx}`;
        let layer_id = `store-outline-${idx}-layer`;
        if (this.map.getLayer(layer_id)) {
          this.map.removeLayer(layer_id);
        }
        if (this.map.getSource(source_id)) {
          this.map.removeSource(source_id);
        }

        this.map.addSource(source_id, {
          'type': 'geojson',
          'data': {
            'type': 'Feature',
            'geometry': location.geometry
          }
        })
        this.map.addLayer({
          'id': layer_id,
          'source': source_id,
          'type': 'fill',
          'paint': {
            'fill-color': color[idx],
            'fill-opacity': 0.8
          }
        })
      });
    },
    addHoverLayer(source_id, table_name) {
      this.source_id = source_id;
      this.source_layer = table_name;
      let hover_layer_id = `hover-layer`
      let layer_config = {
        'id': hover_layer_id,
        'type': 'fill',
        'source': source_id,
        'source-layer': table_name,
        'layout': {},
        'paint': {
          'fill-outline-color': '#2C7BE5',
          'fill-color': 'rgba(0, 0, 0, 0)',
          'fill-pattern': 'pattern',
          'fill-opacity': [
            'case', ['boolean', ['feature-state', 'hover'], false], 
            0.8,
            0
          ]
        }
      }
      if (!this.map.hasImage('pattern')) {
        let img = new Image(100,100);
        img.onload = ()=> {
          this.map.addImage('pattern', img);
          this.map.addLayer(layer_config);
        }
        img.src = StripeSVG;  
      } else {
        this.map.addLayer(layer_config);
      }
      // Hover effect
      this.map.on('mousemove', hover_layer_id, (e) => {
        this.map.getCanvas().style.cursor = 'pointer';
        let features = e.features; //this.map.queryRenderedFeatures(e.point);
        features = [features[0]];
            if (features.length > 0) {
              this.hoverIds.forEach(id => {
                this.map.setFeatureState(
                  { source: source_id, sourceLayer: table_name, id: id },
                  { hover: false }
                );
              });
              this.hoverids = [];
              features.forEach(f => {
                let id = f.id;
                this.map.setFeatureState(
                    { source: source_id, sourceLayer: table_name, id: id },
                    { hover: true }
                );
                this.hoverIds.push(id);
                this.populatePopupData(f);
              })
            }
        });

        // When the mouse leaves the state-fill layer, update the feature state of the
        // previously hovered feature.
        this.map.on('mouseleave', source_id, this.clearHover);
        var mouseTarget = this.map._container;
        mouseTarget.removeEventListener('mouseleave', this.clearHover);
        mouseTarget.addEventListener('mouseleave', this.clearHover); 
        //console.log(this.map);
    },
    clearHover() {
      this.hoverIds.forEach(id => {
        this.map.setFeatureState(
          { source: this.source_id, sourceLayer: this.source_layer, id: id },
          { hover: false }
        );
      });
      this.hoverIds = [];
      this.popup_data.items = [];
    },
    populatePopupData(feature) {
      let id_field = this.geo_table_mapping[this.geolevel]["id_field"];
      let geoid = feature.properties[id_field];
      let geolevel_option = this.geolevel_options.find(g => g.value == this.geolevel);
      this.popup_data.geolabel = geolevel_option.text;
      this.popup_data.title = geoid;
      this.popup_data.items = [];
      // search through aggData for matches
      if (this.populatePopupItems) {
        let matches = this.aggData.rows.filter(r => r.geoid == geoid);
        this.popup_data.items = this.populatePopupItems(matches);
      }
    },
    cleanUpSource(source_id) {
      if (this.map.getSource(source_id)) {
        let layers = this.map.getStyle().layers;
        layers = layers.filter(l => l.source == source_id);
        layers.forEach(l => {
          this.map.removeLayer(l.id);
        });
        this.map.removeSource(source_id);
      }
    },
  }
}