<template>
  <ChartCard
    id="week"
    :isLoading="isLoading"
    :isLoadingToLocation="isLoadingToLocation"
  >
    <template #icon>
      <img
        :src="require('pinnacle-lib/assets/icon/details-avatar.svg')"
        style="margin-right: 14px"
        alt="foot"
      />
    </template>
    <template #title>
      <h2>Demographics</h2>
      <p>Demographics Description Goes here</p>
    </template>
    <template #dropdown>
      <b-button @click="saveCSV('Demographics')" variant="outline-primary">Export CSV</b-button>
    </template>
    <template #name-card>
      <ReportsLegend
        chart-type="dayWeek"
        :studyLocations="studyLocations"
        v-bind:insufficient-data-ids="insufficientDataIds"
      ></ReportsLegend>
    </template>
    <template #charts-container>
      <div id="lineChart" v-if="chartType.week === 'line'">
        <line-chart
          ref="dayOfWeekLineChart"
          :chart-data="week.line.data"
          :options="week.line.option"
          :class="`${isLoading.dayOfWeek ? 'opacity-25' : 'opacity-100'}`"
        />
        <div style="position: absolute; top: 35%; left: 50%"></div>
      </div>
      <div id="barChart" v-if="chartType.week === 'bar'">
        <div v-for="chartConfig in chartConfigs" class="chart-container">
          <HorizontalBarChart
            ref="chartRefs"
            :chart-data="chartConfig.data"
            :options="chartConfig.options"
            :class="`demo-chart ${isLoading ? 'opacity-25' : 'opacity-100'}`"
          />          
        </div>
        <!--bar-chart
          ref="dayOfWeekBarChart"
          :chart-data="week.bar.data"
          :options="week.bar.option"
          :class="`${isLoading ? 'opacity-25' : 'opacity-100'}`"
        /--> 
        <div style="position: absolute; top: 35%; left: 50%"></div>
      </div>
      <div v-if="showWarn" class="warning-container">
        <div class="warning">Data unavailable for the selected dates</div>
      </div>
    </template>
  </ChartCard>
</template>
<script>
import { isEqual } from "lodash";
import LineChart from "@/components/charts/LineChart";
import BarChart from "@/components/charts/BarChart";
import HorizontalBarChart from "@/components/charts/HorizontalBarChart";
import ReportsLegend from "../ReportsLegend.vue";
import { bus } from "@/main";
import {
  getLineGraphOptionsNoTime,
  getBarGraphOptionsNoTime,
} from "@/config/chart";
import MultiDropdownMixin from "pinnacle-lib/mixins/multiDropdown.js";
import ChartsDownloadMixin from "@/services/charts/download";
import ChartUtilMixin from "@/services/charts/chartUtil.js";
import ChartCard from "./ReportChartCard.vue";
import Papa from 'papaparse';
import JSZip from "jszip";

export default {
  components: {
    BarChart,
    HorizontalBarChart,
    LineChart,
    ReportsLegend,
    ChartCard,
  },
  mixins: [ChartsDownloadMixin, MultiDropdownMixin, ChartUtilMixin],
  data() {
    return {
      attempt: false,
      categories: ['Age', 'Income', 'Median Income', 'Median Home Value', 'Education', 'Ethnicity'],
      chartConfigs: [],
      week: {
        line: {
          data: null,
          option: null,
        },
        bar: {
          data: null,
          option: null,
        },
      },
      chartType: {
        month: "line",
        day: "bar",
        week: "bar",
      },
      isLoading: true,
      insufficientDataIds: [],
      show: [true, true, true, true, true],
      showRange: false,
      showWarn: false,
      chartRefs: []
    };
  },
  props: {
    filterString: {
      type: String,
    },
    // id: {
    //   type: Array,
    // },
    studyLocations: {
      type: Array,
      default: function () {
        return [];
      },      
    },
  },
  methods: {
    changeRange(){
      //console.log(this.showRange)
      this.loadChart();
    },
    saveCSV(filename) {
      let zip = new JSZip();
      this.chartConfigs.forEach(chartConfig => {
        let filename = chartConfig.options.title.text;
        let csv_fields = [];
        let csv_data = [];
        csv_fields.push("Place");
        chartConfig.data.labels.forEach(label => {
          csv_fields.push(label);
        });
        chartConfig.data.datasets.forEach(ds => {
          let csv_row = [];
          csv_row.push(`${ds.label} ${ds.sublabel}`);
          csv_row = csv_row.concat(ds.data);
          csv_data.push(csv_row);
        });
        let csv = Papa.unparse({
          "fields": csv_fields,
          "data": csv_data
        });        

        zip.file(`${filename}.csv`, csv);

      });

      zip.generateAsync({type:"blob"}).then(function(content) {
        saveAs(content, `${filename}.zip`);                 
      });
    },
    changeView(view) {
      if (!isEqual(view, this.chartType)) {
        this.chartType = { ...this.chartType, ...view };
      }
    },
    createData(series_data, category) {
      let datasets = [];
      const { color } = this.chartColors;
      for (let i = 0; i < this.ids.length; i++) {
        let polygon = {};
        let data = [];
        let categoryPercentage = 0.7;
        let barPercentage = 1;
        if (category.indexOf('Median') !== -1) {
          data = [series_data[this.ids[i]][category]];
          categoryPercentage = 0.7;
          barPercentage = 1;
        } else {
          data = series_data[this.ids[i]][category].map((item) => item.value.toFixed(2));
        }
        
        polygon = {
          label: this.studyLocations[i].name,
          polygonId: this.studyLocations[i].id,
          data: data,
          borderColor: color[i],
          backgroundColor: color[i],
          fill: false,
          hidden: !this.show[i],
          categoryPercentage: categoryPercentage,
          barPercentage: barPercentage,
          barThickness: 'flex',
          maxBarThickness: 40,
          datalabels: {
            display: false,
          },
        };
        datasets.push(polygon);
      }
      return datasets;
    },
    setData(res) {
      const [series_data, median] = res;
      if (!Object.entries(series_data).length) return;
      // step through catgories
      this.categories.forEach((category, i) => {
        let temp = Object.values(series_data);
        //console.log(temp);
        let first_polygon_data = temp[0];
        let labels = [];
        if (category.indexOf('Median') !== -1) {
          labels = [category];
        } else {
          labels = first_polygon_data[category].map((item) => item.label);
        }

        let options = {
          ...getBarGraphOptionsNoTime({
              labelStringXAxes: category,
              labelStringYAxes: "Percent of Visits",
              tooltipSuffix: "%",
          }),
          title: {
            display: true,
            fontColor: '#12263f',
            fontSize: 15,
            text: category
          },
          scales: {
            xAxes: [{
              ticks: {
                callback: function(value, index, ticks) {
                      return value + '%';
                }                  
              }
            }]
          }        
        }

        if (category.indexOf('Median') !== -1) {
          options.scales = {
            yAxes: [{
                ticks: {
                    display: true //this will remove only the label
                }
            }],
            xAxes: [{
              ticks: {
                maxTicksLimit: 5,
                callback: function(value, index, ticks) {
                  return '$' + value / 1000 + 'K';
                }                  
              }
            }]
          }
          options.tooltips = {
            callbacks: {
              label: function(tooltipItem, data) {
                let value = data['datasets'][tooltipItem['datasetIndex']]['data'][tooltipItem['index']];
                value = Math.round(value / 1000);
                return data['datasets'][tooltipItem['datasetIndex']]['label'] + ' $' + value + 'K';
              },
            },
          }
        }

        let chartConfig = {
          category: category,
          data: {
            labels: labels,
            datasets: [],
          },
          options: options,
        }
        // TODO add median data

        // add data from series_data
        chartConfig.data.datasets = this.createData(series_data, category);
        this.chartConfigs[i] = chartConfig;        
      });
      //console.log(this.chartConfigs);

  
      //this.saveCSV();
      this.isLoading = false;
      this.renderChartRefs();
    },
    loadChart() {
      if (this.studyLocations && this.studyLocations.length > 0) {
        this.requestData().then((res) => {
          this.setData(res);
        });
      }
    },
    async requestData() {
      this.showWarn = false;
      let id_str = this.reportId; 
      const promises = [this.getChartData(`/v2/daily-visits-demographics/${id_str}`)]; 
      const responses = await Promise.allSettled(promises);
      // Check Insufficient Data
      let insufficientDataIds = [];
        //responses[0].value.data.meta_data.insufficientDataIds;
      if (insufficientDataIds) {
        this.insufficientDataIds = insufficientDataIds;
      }
      const seriesData = Object.entries(responses[0].value.data.series_data);
      if (this.insufficientDataIds.length === seriesData.length) {
        this.showWarn = true;
        this.isLoading = false;
        return;
      }
      let week = responses[0].value.data;
      this.weekData = responses[0].value.data;
      let weekEntries = week.series_data;
      let temp = Object.entries(weekEntries);
      let median = temp[0][1]['Age'];
      
      return [weekEntries, median];
    },
  },
  mounted() {
    //this.registerMultiDropdown(this.$refs.weekOptionsMenu);
    let ids = this.ids;
    let tempArr = [true, true, true, true, true];
    Object.entries(this.disabledIds).forEach(function (o) {
      if (ids.indexOf(o[0]) !== undefined) {
        tempArr[ids.indexOf(o[0])] = false;
      }
    });
    this.show = tempArr;
    bus.$on("locationsListChanged", (res) => {
      const rangeBandData = this.calcMinMax(this.weekData.series_data, "percent", "dow");
      this.handleLocationsListChanged(res, this.week, rangeBandData, (line) => {
        return line.map(values => values.percent.toFixed(2));
      });
      this.renderChartRefs();
    });
    this.loadChart();

    // bus.$on("filters", (res) => {
    //   //this.filterString = res;
    //   this.isLoading = true;
    //   if (this.isLoadingToLocation === false) {
    //     this.requestData().then((res) => {
    //       this.setData(res);
    //     });
    //   } else {
    //     this.attempt = true;
    //   }
    // });
    bus.$on("onHover", (res) => this.handleOnHover(res));
  },
  computed: {
    showMedian() {
      return false;
      //return this.$sessionStore.state.study.showMedian;
    },
    location() {
      //this will check if route is /preview, and pull from the appropriate vuex store
      return this.$route.path.split("/")[1] === "preview"
        ? this.$sessionStore.state.study.previews
        : this.$sessionStore.state.study.locations;
    },
    isLoadingToLocation() {
      return this.$sessionStore.state.study.loading;
    },
    disabledIds() {
      return this.$sessionStore.state.study.disabledIds;
    },
    medianName() {
      return this.$sessionStore.state.study.name;
    },
    reportId() {
      //console.log('route', this.$route);
      return this.$route.params.id;
    },
    ids() {
      //console.log("studyLocation", this.studyLocations);
      let temp = this.studyLocations.map((loc) => loc.id)
      //console.log("ids", temp);
      //return [10786834,10787526,10893789,10478372];
      return temp;
    },
    showArray() {
      return this.studyLocations.filter((loc) => loc.show).map(l => l.id);
    }
  },
  watch: {
    isLoadingToLocation() {
      if (!this.isLoadingToLocation && this.attempt) {
        this.requestData().then((res) => {
          this.setData(res);
        });
      }
    },
    studyLocations(arr) {
      if (arr && arr.length > 0) {
        this.loadChart();
      }
    },
    ids() {
      if (this.ids.length > 0) {
        this.requestData().then((res) => {
          this.setData(res);
        });
      }
    },
    showArray(arr) {
      if (typeof this.$refs.chartRefs !== "undefined") {
        this.$refs.chartRefs.forEach(chartInstance => {
          if (typeof chartInstance !== "undefined") {
            chartInstance.chartData.datasets.forEach(function(ds, index) {
              let polygonId = ds.polygonId;
              if (polygonId) {
                ds.hidden = !arr.includes(polygonId);
              }
            });
            //chartInstance.render();
          }
        })
        this.renderChartRefs();
      }
    }
  },
  // beforeDestroy() {
  //   this.unwatch();
  // },
};
</script>

<style lang="scss" scoped>
@import "./ReportChartStyles.scss";
</style>