<template>
  <div id="charts">
    <div class="chart-container">
      <ReportTitle :reportDetails="reportDetails" />

      <ReportEstFoot :filterString="filterString" :studyLocations="studyLocations" />
      <ReportDayWeek :filterString="filterString" :studyLocations="studyLocations" />
      <ReportMobileTradeArea :filterString="filterString" :studyLocations="studyLocations" />
      <ReportMarketShare :filterString="filterString" :studyLocations="studyLocations" />
      <ReportDemographics :filterString="filterString" :studyLocations="studyLocations" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { bus } from "@/main";
import { getISO3 } from "@/utils/countryMapping";

import ReportTitle from "./charts/ReportTitle.vue";
import ReportEstFoot from "./charts/ReportEstFoot.vue";
import ReportDayWeek from "./charts/ReportDayWeek.vue";
import ReportMobileTradeArea from "./charts/ReportMobileTradeArea.vue";
import ReportMarketShare from "./charts/ReportMarketShare.vue";
import ReportDemographics from "./charts/ReportDemographics.vue";

export default {
  components: {
    ReportTitle,
    ReportEstFoot,
    ReportDayWeek,
    ReportMobileTradeArea,
    ReportMarketShare,
    ReportDemographics,
  },
  props: ["reportDetails", "studyLocations"],
  data() {
    return {
      showMedian: true,
      filters: {
        start_date: moment().startOf("year").format("MM/DD/YY"),
        end_date: moment()
          .endOf("year")
          .subtract(3, "month")
          .format("MM/DD/YY"),
      },
    };
  },
  mounted() {
    const _t = this;

    bus.$off("setFilters");
    bus.$on("setFilters", (filters) => {
      this.setFilters(filters);
    });
    this.$nextTick(() => {
      bus.$emit("chartBusReady");
    });
  },
  beforeDestroy(){
    bus.$off("filters");
    bus.$off("locationsListChanged");
    bus.$off("comparisonCheck");
    bus.$off("onHover");
  },
  computed: {
    filterString() {
      const filters = this.filters;
      const filterStringsArray = Object.entries(filters).map(
        (f) => `${f[0]}=${f[1]}`
      );
      return filterStringsArray.reduce(
        (acc, curr, i) =>
          `${acc}${curr}${i < filterStringsArray.length - 1 ? "&" : ""}`,
        ""
      );
    },
    isLoadingToLocation() {
      return this.$sessionStore.state.study.loading;
    },
    location() {
      //this will check if route is /preview, and pull from the appropriate vuex store
      return this.$route.path.split('/')[1] === 'preview' ? this.$sessionStore.state.study.previews : this.$sessionStore.state.study.locations;
    },
    id() {
      let idArr = this.$route.params.id.split(",");
      return idArr || "";
    },
    locationInfo() {
      return this.location.shortenedName + " - " + this.location.address;
    },
  },
  methods: {
    setFilters(filters) {
      const _t = this;
      this.filters = { ...this.filters, ...filters };
      this.$nextTick(() => {
        bus.$emit("filters", this.filterString);
      });
    },
    getCountryCode() {
      const countryCode = this.location.country_code;
      return getISO3(countryCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.line {
  border-bottom: 1px solid #edf2f9;
  margin-top: 24px;
  margin-left: -25px;
  margin-right: -25px;
}
// .dateRangeDetails {
// position: absolute;
// margin-top: 10px;
// margin-left: -5px;
// }
#charts {
  // position: relative;
  position: absolute;
  // height: calc(100% - 150px);
  width: 100%;
  height: 100%;
  background: #f9fafc;
  padding: 1.5rem;
  overflow-y: scroll;
  
  ::v-deep.card {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e3ebf6;
    box-sizing: border-box;
    border-radius: 10px;
  }

  ::v-deep .title {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  ::v-deep .location {
    padding: 20px;
    margin-bottom: 12px;
    .location-info,
    .loc-name {
      flex: auto;
    }
    .location-info {
      padding: 0 15px;
    }
    .brand-img {
      width: 57px;
      height: 57px;
      background: #eaf2fd;
      padding: 16.5px;
      border-radius: 8px;
      margin-right: 15px;
    }
    p {
      margin-bottom: 0;
    }
    .name {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 23px;
      letter-spacing: -0.01em;
      color: #12263f;
    }
    .address,
    .visitor {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: -0.01em;
    }
    .address {
      color: #e5e6e9;
    }
    .visitor {
      color: #95aac9;
    }
    .visitor img {
      margin-right: 5px;
    }
    &.disabled {
      opacity: 0.3;
    }
  }

  ::v-deep .add-location-btn {
    width: 100%;
    height: 40px;
    background: #2c7be5;
    border: 1px solid #2c7be5;
    box-sizing: border-box;
    border-radius: 6px;
    outline: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.01em;
    color: #ffffff;
    margin-top: 7px;
    &:disabled {
      opacity: 0.3;
    }
  }
  .chart-container {
    flex: auto;

    .contact-card {
      height: 261px;
      padding: 0 75px 0 49px;
      margin-bottom: 20px;
      .content {
        flex: auto;
        padding: 0;
      }
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: -0.02em;
        color: #12263f;
        margin-bottom: 7px;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        letter-spacing: -0.01em;
        color: #e5e6e9;
        margin-bottom: 10px;
      }
      .contact-btn,
      .close-btn {
        width: 118px;
        height: 40px;
        box-sizing: border-box;
        border-radius: 6px;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -0.01em;
        padding: 0;
      }
      .contact-btn {
        background: #2c7be5;
        border: 1px solid #2c7be5;
        color: #ffffff;
        margin-right: 20px;
      }
      .close-btn {
        background: #ffffff;
        border: 1px solid #e5e6e9;
        color: #e5e6e9;
      }
      .img-container {
        width: 270px;
        height: 190px;
        position: relative;
      }
      .img-container img {
        position: absolute;
        top: 0;
        right: -100px;
      }
    }
  }
}
#timeFrameModal {
  .modal-content {
    width: 554px;
  }
  .modal-body {
    padding: 24px;
  }
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.02em;
    color: #12263f;
    margin-bottom: 7px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #e5e6e9;
    margin-bottom: 20px;
  }
  select {
    width: 100%;
    height: 34px;
    padding: 0 10px;
    background: #ffffff;
    border: 1px solid #e3ebf6;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #3b516c;
    margin-bottom: 20px;
    outline: 0;
  }
  .filter-btn {
    width: 115.59px;
    height: 41.9px;
    margin-left: auto;
    background: #2c7be5;
    border: 1px solid #2c7be5;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#viewDetailsModal {
  .modal-content {
    width: 596px;
  }
  .modal-body {
    padding: 24px;
  }
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.02em;
    color: #12263f;
    margin-bottom: 22px;
  }
  .card {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e3ebf6;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 24px;
  }
}
</style>

