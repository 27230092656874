<template>
  <div class="card chart-card">
    <div class="d-flex align-items-center justify-content-between">
      <div class="">
        <h2>{{ reportDetails.name }}</h2>
        <p>Timeframe: 07/29/2023 - 07/29/2024</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["reportDetails"],
}
</script>
<style lang="scss" scoped>
@import "./ReportChartStyles.scss";
</style>